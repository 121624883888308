<template>
  <List
    :title="'Site'"
    :entries="bodyItems"
    :headItems="headItems"
    :hasCheck="true"
    :canCreate="canCreate"
    :loaded="loaded"
    :toFilter="toFilter"
    :tabFilters="tabFilters"
    :isActive="isActive"
    @optionSel="handleOption"
    @create="handleCreate"
    @filters="filterByItem"
    @actions="handleAction"
    @search="handleSearch"
  />
  <Pagination
    :entries="entries"
    :currentPage="currentPage"
    :total="total"
    :perPage="perPage"
    :pages.="pages"
    @changePage="handlePageChange"
  />
  <see-modal
    v-if="see"
    :title="`Site Card`"
    :items="toSee"
    @close="see = false"
  />
  <edit-modal
    v-if="editItem"
    :title="`Edit Site`"
    :items="toEdit"
    @close="editItem = false"
    @submitData="editSite"
  />
  <Create
    v-if="create"
    :items="toCreate"
    :response="response"
    @data="handleCreateData"
    @close="create = false"
  />
</template>

<script>
import { mapGetters } from "vuex";
import { GET_USER_PROFILE_GETTER } from "@/store/storeconstants";
import axiosInstance from "@/services/AxiosTokenInstance";
import List from "@/components/List/Index.vue";
import Pagination from "@/components/List/Pagination.vue";
import timeSince from "@/mixins/timeSince";
import isMobile from "@/mixins/isMobile";
import SeeModal from "@/components/Ui/CRUD/See.vue";
import EditModal from "@/components/Ui/CRUD/Edit.vue";
import Create from "@/components/Create/Index.vue";
import api from "@/mixins/optionsApi";

export default {
  title() {
    return `Remote Assistance | ${this.title}`;
  },
  data() {
    return {
      title: "Sites",
      entries: [],
      currentPage: 1,
      filteredEntries: [],
      total: 0,
      pages: 0,
      perPage: 10,
      imgURL: process.env.VUE_APP_DO_SPACES,
      loaded: false,
      filter: [],
      canCreate: [4, 5, 6, 7, 8, 9],
      headItems: ["Site Name", "Client", "Products", "Users"],
      bodyItems: [],
      editOptions: ["see", "edit"],
      search_key: "",
      hasOptions: true,
      see: false,
      editItem: false,
      create: false,
      selItem: 0,
      client: localStorage.getItem("client")
        ? JSON.parse(localStorage.getItem("client"))
        : null,
      tabFilters: [
        {
          name: "All Sites",
          filter: "",
          isActive: true,
        },
      ],
      isActive: 0,
      no: false,
      response: [],
    };
  },

  provide() {
    return {
      edit: this.editOptions,
      hasOptions: this.hasOptions,
    };
  },

  components: {
    List,
    Pagination,
    SeeModal,
    EditModal,
    Create,
  },

  mixins: [timeSince, isMobile, api],

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.getData(val);
      },
    },

    filter() {
      this.getData(1);
    },

    entries() {
      this.fillBodyItems();
    },

    search_key() {
      this.getData(1);
    },
  },

  computed: {
    ...mapGetters("account", {
      getUserProfile: GET_USER_PROFILE_GETTER,
    }),
    currentTotal() {
      return this.total > 10 && this.perPage * this.currentPage < this.total
        ? this.perPage * this.currentPage
        : this.total;
    },
    toFilter() {
      let toReturn = [
        {
          name: "products",
          options: this.api("products"),
        },
      ];
      if (
        this.getUserProfile.role.id !== 9 &&
        this.getUserProfile.role.id !== 10
      ) {
        toReturn.push({
          name: "client",
          options: this.api("clients"),
        });
      }
      return toReturn;
    },
    toCreate() {
      if (this.getUserProfile.role.id === 9) {
        return [
          {
            type: "text",
            name: "name",
            label: "Site Name",
            required: true,
          },
          {
            type: "select",
            name: "country",
            label: "Country",
            options: this.api("countries"),
            required: false,
          },
          {
            type: "multiple-select",
            name: "products",
            label: "Products",
            options: this.api("products"),
            required: false,
          },
          {
            type: "multiple-select",
            name: "users",
            label: "Users",
            options: this.api("client_users"),
            required: false,
          },
        ];
      }
      return [
        {
          type: "text",
          name: "name",
          label: "Site Name",
          required: true,
        },
        {
          type: "select",
          name: "country",
          label: "Country",
          options: this.api("countries"),
          required: false,
        },
        {
          type: "multiple-select",
          name: "products",
          label: "Products",
          options: this.api("products"),
          required: false,
        },
        {
          type: "select",
          name: "client",
          label: "Client",
          options: this.api("clients"),
          required: true,
        },
        {
          type: "multiple-select",
          name: "users",
          label: "Users",
          options: this.api("client_users"),
          required: false,
        },
      ];
    },
    toSee() {
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      if (Object.keys(item).length) {
        return [
          {
            title: "Header",
            image: item.logo ? this.imgURL + item.logo : null,
            value: item.name,
            id: item.id,
          },
          {
            title: "Clients",
            value: item.client ? item.client.client_details.name : "none",
          },
          {
            title: "Products",
            value: this.productsToSend.length ? this.productsToSend : "none",
          },
          {
            title: "Last Updated",
            value: this.timeSince(item.updated_at),
          },
        ];
      }
      return [];
    },
    toEdit() {
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      if (Object.keys(item).length) {
        let toReturn = [
          {
            type: "text",
            label: "Name",
            value: item.name,
          },
          {
            type: "select",
            label: "Country",
            options: this.api("countries"),
            value: {
              id: item.country_id,
              name: item.country_name ? item.country_name : "Test",
            },
          },
          {
            type: "multiple-select",
            label: "Products",
            options: this.api("products"),
            value: item.product_list.length
              ? item.product_list
                  .filter((el) => el.products_details)
                  .map((prod) => {
                    return {
                      rel_id: prod.id,
                      id: prod.id_product,
                      name: prod.products_details.name,
                      image: null,
                    };
                  })
              : [],
          },
          {
            type: "multiple-select",
            label: "Users",
            options: this.api("users"),
            value: item.users.length
              ? item.users.map((user) => {
                  return {
                    rel_id: user.id,
                    id: user.id_user,
                    name: user.user_details.name,
                    image: user.user_details.avatar
                      ? this.imgURL + user.user_details.avatar
                      : null,
                  };
                })
              : [],
          },
          {
            type: "select",
            label: "Status",
            options: [
              { id: 2, name: "Inactive" },
              { id: 1, name: "Active" },
            ],
            value: {
              id: item.status === 1 ? 1 : 2,
              name: item.status === 1 ? "Active" : "Inactive",
            },
          },
        ];

        if (this.getUserProfile.role.id !== 9) {
          const toAdd = {
            type: "select",
            label: "Client",
            options: this.api("clients"),
            value: item.client
              ? item.client.client_details
                ? {
                    id: item.client.client_details.id,
                    name: item.client.client_details.name,
                    image: item.client.client_details.logo
                      ? this.imgURL + item.client.client_details.logo
                      : null,
                  }
                : []
              : [],
          };
          toReturn.splice(2, 0, toAdd);
        }
        return toReturn;
      }
      return [];
    },

    productsToSend() {
      let toReturn = [];
      const item = this.entries.find((el) => el.id === this.selItem) || {};
      const prod_names = item.product_list
        .filter((el) => el.products_details)
        .map((el) => el.products_details.name);
      prod_names.forEach((el) => toReturn.push({ name: el }));
      return toReturn;
    },
  },
  methods: {
    handlePageChange(to) {
      switch (to) {
        case "less":
          this.currentPage -= 1;
          break;
        case "more":
          this.currentPage += 1;
          break;
      }
    },

    fillBodyItems() {
      this.bodyItems = this.entries.map((item) => {
        const products = [];
        item.product_list
          .filter((el) => el.products_details)
          .map((el) => el.products_details)
          .map((el) => {
            if (!products.some((prod) => prod.name === el.name))
              products.push(el);
          });

        return [
          {
            template: "image",
            id: item.id,
            data: {
              image: "none",
              title: item.name,
              description: [
                {
                  light: false,
                  text: `created ${this.timeSince(item.created_at)}`,
                },
              ],
            },
          },
          {
            template: "normal",
            data:
              item.client && item.client.client_details
                ? item.client.client_details.name
                : "undefined",
          },
          {
            template: "multiple",
            data: products,
          },
          {
            template: "multiple-icons",
            data: item.users.map((el) => {
              return {
                id: el.id_user,
                name:
                  el.user_details && el.user_details.name
                    ? el.user_details.name
                    : "undefined",
                avatar:
                  el.user_details && el.user_details.avatar
                    ? this.imgURL + el.user_details.avatar
                    : null,
              };
            }),
          },
        ];
      });
    },

    async getData(value) {
      this.loaded = false;
      let orgDataString = localStorage.getItem("organization");
      this.entries = [];
      this.perPage = 0;
      this.total = 0;
      this.pages = 0;
      const client_id = this.client ? this.client.id : "";
      if (orgDataString) {
        let orgData = JSON.parse(orgDataString);
        let response;
        if (!this.filter.length)
          response = await axiosInstance
            .get(
              `new-site-list?org_id=${orgData.id}&searchKey=${this.search_key}&page=${value}&clientId=${client_id}&limit=10`
            )
            .finally(() => (this.loaded = true));
        else
          response = await axiosInstance
            .get(
              `new-site-list?org_id=${orgData.id}}&searchKey=${this.search_key}&${this.filter[0]}=${this.filter[1]}&clientId=${client_id}&page=${value}&limit=10`
            )
            .finally(() => (this.loaded = true));
        let responseData = response.data.data;
        this.entries = responseData.data;
        this.perPage = Number(responseData.per_page);
        this.total = responseData.total;
        this.pages = responseData.last_page;
      }
    },

    filterByItem(data) {
      switch (data[0]) {
        case "products":
          this.filter = ["productId", data[1]];
          break;
        case "remove":
          this.filter = [];
          break;
      }
    },

    handleCreate(value) {
      this.create = value;
    },

    async handleCreateData(data) {
      const org = JSON.parse(localStorage.getItem("organization"));
      const client_id = this.client ? this.client.id : "";
      const toSend = {
        org_id: org.id,
        country_id: data.country?.id,
        name: data.name,
        description: "empty",
        product_ids: data.products ? data.products.map((el) => el.id) : [],
        users: data.users ? data.users.map((el) => el.id) : [],
      };

      if (this.getUserProfile.role.id === 9) toSend.client_id = client_id;
      else toSend.client_id = data.client?.id;

      if (this.getUserProfile.role.id === 9)
        toSend.users.push(this.getUserProfile.id);

      const response = await axiosInstance.post(`manage-site`, toSend);

      if (response.status === 200) {
        this.response = [true, true];
        this.handleAlert(response.data.message);
        this.getData(1);
      } else {
        this.response = [true, false];
        this.handleAlert("Something went wrong");
      }
    },

    handleAlert(data) {
      this.emitter.emit("alert", data);
    },

    handleOption(data) {
      this.selItem = data[1];
      switch (data[0]) {
        case "see":
          this.see = true;
          break;
        case "edit":
          this.editItem = true;
          break;
        case "delete":
          this.deteleSite();
          break;

        default:
          break;
      }
    },

    async editSite(data) {
      const org = JSON.parse(localStorage.getItem("organization"));
      const item = this.entries.find((el) => el.id === this.selItem) || {};

      let toSend = {
        org_id: org.id,
        site_id: item.id,
        name: data.Name,
        new_products: data.newProducts
          ? data.newProducts.map((el) => el.id)
          : [],
        deleted_products: data.deletedProducts
          ? data.deletedProducts.map((el) => el.rel_id)
          : [],
        status: data.Status.id,
        country_id: data.Country.id,
      };

      if (data.Client && data.Client.id !== item.client.client_id) {
        toSend.new_client = data.Client.id;
        toSend.deleted_client = item.client.id;
      }

      if (data.newUsers) {
        toSend.new_users = data.newUsers.map((el) => {
          return { user_id: el.id };
        });
      }

      if (data.deletedUsers) {
        toSend.deleted_users = data.deletedUsers.map((el) => el.id);
      }

      const res = await axiosInstance.post(`update-site`, toSend);

      if (res.status === 200) {
        this.handleAlert(res.data.message);
        this.getData(1);
      }

      this.selItem = 0;
    },

    async deteleSite() {
      const res = await axiosInstance.post(`site/delete`, {
        site_id: this.selItem,
      });

      if (res.status === 200) {
        this.handleAlert(res.data.message);
        this.getData(1);
      }

      this.selItem = 0;
    },

    handleAction(data) {
      if (data.name === "delete selected") {
        data.data.forEach(async (element) => {
          await axiosInstance
            .post(`site/delete`, {
              site_id: element,
            })
            .finally(() => {
              this.getData(1);
            });
        });
      }
    },

    handleSearch(data) {
      this.search_key = data;
    },
  },
};
</script>